import { Injectable } from '@angular/core';
import { Institution } from '../../institution/institution.model';
import { BASE_SURVEY_URL, institutionToSurveyId } from '../survey.config';
import { ProfileTypeString } from '../../../modules/profile/store/profile.entity';

@Injectable()
export class SurveyService {

    getSurveyLink(profileType: ProfileTypeString, institution: Institution): string {
        const surveyIds = institutionToSurveyId[institution] ?? institutionToSurveyId[Institution.PATIENTUS];
        return BASE_SURVEY_URL + surveyIds[profileType];
    }
}
