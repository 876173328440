import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ModalCloseAction } from '../store/modal.action';
import { AppState, ModalCategory } from '../store/modal.state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SurveyService } from '../../../entities/survey/provider/survey.service';
import { ProfileService } from '../../profile/providers/profile.service';
import { getProfileType, Profile, ProfileTypeString } from '../../profile/store/profile.entity';

@Component({
    selector: 'app-survey-overlay',
    templateUrl: './survey-overlay.component.html',
    styleUrls: ['./survey-overlay.component.scss'],
    standalone: false
})
export class SurveyOverlayComponent implements OnInit, OnDestroy {

    surveySelected = true;
    surveyLink: string;
    public showSurvey = false;

    private surveyOverlaySubscription: Subscription = null;

    constructor(
        private store: Store<AppState>,
        private survey: SurveyService,
        private profileService: ProfileService
    ) {
    }

    ngOnInit() {
        this.surveyOverlaySubscription = this.store.select(s => s.modal).subscribe(modalStore => {
                this.showSurvey = (modalStore.isActive && (modalStore.category === ModalCategory.SURVEY_POPUP));

                // NOTE: this is part of a dirty hack to fix an iOS scrolling issue with the zoho-survey iframe.
                if (this.showSurvey === true) {
                    setTimeout(function() {
                        const dashboardWrapper =  document.getElementById('dashboard-wrapper');
                        if (dashboardWrapper) {
                            dashboardWrapper.setAttribute('style', 'position: fixed; width: 100%;');
                        } else {
                            document.getElementById('simpleLayout')
                                .setAttribute('style', 'position: fixed; width: 100%;');
                        }
                    }, 500);
                } else if (
                    !this.showSurvey &&
                    (document.getElementById('dashboard-wrapper') || document.getElementById('simpleLayout'))
                ) {
                    const dashboardWrapper =  document.getElementById('dashboard-wrapper');
                    if (dashboardWrapper) {
                        dashboardWrapper.removeAttribute('style');
                    } else {
                        document.getElementById('simpleLayout').removeAttribute('style');
                    }
                }
            }
        );

        this.profileService
            .getCurrentProfileDataObserver().pipe(
                take(1),
                map(profile => {
                    const surveyLink = this.survey.getSurveyLink(
                        <ProfileTypeString>getProfileType(profile.type),
                        profile.institution
                    );
                    return this.addProfileDataToLink(surveyLink, profile);
                }),
            ).subscribe((link) => {
                this.surveyLink = link;
            });
    }

    private addProfileDataToLink(link: string, profile: Profile): string {
        link = link + '?id=' + profile.id;
        if (profile.email) {
            link = link + '&email=' + profile.email;
        }
        return link;
    }

    startSurvey() {
        this.surveySelected = true;
    }

    closeSurvey() {
        // NOTE: this is part of a dirty hack to fix an iOS scrolling issue with the zoho-survey iframe.
        const dashboardWrapper =  document.getElementById('dashboard-wrapper');
        if (dashboardWrapper) {
            dashboardWrapper.removeAttribute('style');
        } else {
            document.getElementById('simpleLayout').removeAttribute('style');
        }
        this.showSurvey = false;
        this.surveySelected = true;
        this.store.dispatch(new ModalCloseAction());
    }

    ngOnDestroy(): void {
        if (this.surveyOverlaySubscription !== null) {
            this.surveyOverlaySubscription.unsubscribe();
        }
    }
}
