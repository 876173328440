import { Institution } from '../institution/institution.model';
import { InstitutionSurveyId } from './model/survey.model';
import { ProfileTypeString } from '../../modules/profile/store/profile.entity';

export const BASE_SURVEY_URL = 'https://umfrage.patientus.de/zs/';

export const institutionToSurveyId: { [key in Institution]?: InstitutionSurveyId } = {
    [Institution.PATIENTUS]: {
        [ProfileTypeString.DOCTOR]: 'eeBB2q',
        [ProfileTypeString.PATIENT]: 'kBB8VE'
    },
    [Institution.CFG_AOK]: {
        [ProfileTypeString.DOCTOR]: 'JQCC9o',
        [ProfileTypeString.PATIENT]: 'XbCC9h'
    },
    [Institution.KINZIGTAL]: {
        [ProfileTypeString.DOCTOR]: '6sBBPT',
        [ProfileTypeString.PATIENT]: 'kBB8VE'
    },
    [Institution.ASD_BGN]: {
        [ProfileTypeString.DOCTOR]: 'eeBB2q',
        [ProfileTypeString.PATIENT]: 'kBB8VE'
    },
    [Institution.HOMEDOK]: {
        [ProfileTypeString.DOCTOR]: 'XxBBYg',
        [ProfileTypeString.PATIENT]: 'AWB83F'
    },
    [Institution.JAMEDA]: {
        [ProfileTypeString.DOCTOR]: 'HJBBZw',
        [ProfileTypeString.PATIENT]: 'ZYB894'
    },
    [Institution.BETRIEBSARZTSERVICE]: {
        [ProfileTypeString.DOCTOR]: 'gMBBCl',
        [ProfileTypeString.PATIENT]: '8eB8O9'
    },
    [Institution.BAD]: {
        [ProfileTypeString.DOCTOR]: 'zVBBxb',
        [ProfileTypeString.PATIENT]: 'VGB8wN'
    },
    [Institution.HELIOS]: {
        [ProfileTypeString.DOCTOR]: '0wBB9o',
        [ProfileTypeString.PATIENT]: 'xZB8Hj'
    },
    [Institution.MEDIMENTUM]: {
        [ProfileTypeString.DOCTOR]: 'bEB8cQ',
        [ProfileTypeString.PATIENT]: 'G8B8Nr'
    },
    [Institution.SANASO]: {
        [ProfileTypeString.DOCTOR]: 'YNB8bh',
        [ProfileTypeString.PATIENT]: 'JkB8FA'
    },
    [Institution.ACURA]: {
        [ProfileTypeString.DOCTOR]: 'LdB8OJ',
        [ProfileTypeString.PATIENT]: 'qLB80Q'
    },
    [Institution.AGAPLESION]: {
        [ProfileTypeString.DOCTOR]: 'eeBB2q',
        [ProfileTypeString.PATIENT]: 'YOhrNp'
    },
    [Institution.UKSH]: {
        [ProfileTypeString.DOCTOR]: 'eeBB2q',
        [ProfileTypeString.PATIENT]: 'TeB8gq'
    },
    [Institution.ZOTZKLIMAS]: {
        [ProfileTypeString.DOCTOR]: 'YYB8T3',
        [ProfileTypeString.PATIENT]: 'NnB8AY'
    }
};
